
export default {
  props: {
    id: {
      type: String,
      default: null,
    },
    title: {
      type: String,
      default: null,
    },
    subtitle: {
      type: String,
      default: null,
    },
    intro: {
      type: String,
      default: null,
    },
    featuredImage: {
      type: String,
      default: null,
    },
    featuredVideo: {
      type: Array,
      default: null,
    },
    content: {
      type: Array,
      default: null,
    },
    seo: {
      type: Object,
      default: null,
    },
    social: {
      type: Object,
      default: null,
    },
    preprOverwrite: {
      type: Object,
      default: null,
    },
    cookiePermissions: {
      type: Array,
      default: null,
    },
    atUserId: {
      type: [String, Number],
      default: null,
    },
    atUserIdDuration: {
      type: [String, Number],
      default: null,
    },
    atSiteId: {
      type: [String, Number],
      default: null,
    },
  },
}
