
import { eoRendererMixin, metaDataMixin } from '~/mixins'

export default {
  mixins: [
    eoRendererMixin,
    metaDataMixin,
  ],
  async asyncData ({ $api, $config, $log, store, params: { pathMatch }, error }) {
    // get the page and its parent page
    const slug = pathMatch
    const slugParent = pathMatch?.split('/')?.slice(-2, -1).join('/')

    const [
      {
      data: {
        items: page = [],
      } = {},
      },
      {
        data: {
          items: pageParent = [],
        } = {},
      },
    ] = await Promise.all([
      pathMatch && $api.pages.getOneBySlug(slug, {
        domain: $config.preprEnvironmentDomainEo,
      }),
      slugParent && $api.pages.getOneBySlug(slugParent, {
        domain: $config.preprEnvironmentDomainEo,
      }),
    ])
    // check publication if exists and is complete
    if (!page || !page.length) {
      $log.warn(`Couldn't create '${slug}' page.`)
      return error({ statusCode: 404, message: 'Oeps! De pagina is niet gevonden' })
    }

    store.dispatch('breadcrumbs/set', {
      path: `/${pathMatch}`,
      width: 6,
      items: [
        {
          to: `/${slugParent}`,
          label: pageParent[0]?.title || 'Over de EO',
        },
        {
          label: page[0]?.title,
        },
      ],
    })
    return page[0]
  },
  data () {
    return {
      id: null,
      title: null,
      subtitle: null,
      intro: null,
      hero: null,
      featuredVideo: null,
      content: null,
      seo: null,
      slug: null,
      social: null,
    }
  },
}
